@import 'variables';
.flex-shrink-0  { flex-shrink: 0; }
.flex-shrink-1  { flex-shrink: 1; }
.flex-grow-0    { flex-grow: 0; }
.flex-grow-1    { flex-grow: 1; }
.text-left      { text-align: left; }
.text-center    { text-align: center; }
.text-right     { text-align: right; }
.text-smaller   { font-size: 1.5rem; }
.text-bold      { font-weight: bold; }
.font-weight-500 { font-weight: 500; }
.h-100 {height: 100%;}
.w-100 {width: 100%;}
.h-300 {height: 300px;}
.h-450 {height: 450px;}
.align-items-start      { align-items: start; }
.align-items-center     { align-items: center; }
.align-items-end        { align-items: flex-end; }
.justify-content-center { justify-content: center; }
.justify-content-between{ justify-content: space-between; }
.justify-content-around { justify-content: space-around; }
.justify-content-start  { justify-content: flex-start; }
.justify-content-end    { justify-content: flex-end; }
.d-none                 { display: none; }
.d-flex                 { display: flex; }
.d-block                { display: block; }
.d-i-block              { display: inline-block; }
.d-i-flex              { display: inline-flex; }
.mt-48                  { margin-top: 48px; }
.mr-48                  { margin-right: 48px; }
.mb-48                  { margin-bottom: 48px; }
.ml-48                  { margin-left: 48px; }
.m-48                   { margin: 48px; }
.mt-32                  { margin-top: 32px; }
.mr-32                  { margin-right: 32px; }
.mb-32                  { margin-bottom: 32px; }
.ml-32                  { margin-left: 32px; }
.m-32                   { margin: 32px; }
.mt-16                  { margin-top: 16px; }
.mr-16                  { margin-right: 16px; }
.mb-16                  { margin-bottom: 16px; }
.ml-16                  { margin-left: 16px; }
.m-16                   { margin: 16px; }
.mt-8                   { margin-top: 8px; }
.mr-8                   { margin-right: 8px; }
.mb-8                   { margin-bottom: 8px; }
.ml-8                   { margin-left: 8px; }
.m-8                    { margin: 8px; }
.mt-0                   { margin-top: 0px !important; }
.m-0                    { margin: 0px; }
.pt-48                  { padding-top: 48px; }
.pr-48                  { padding-right: 48px; }
.pb-48                  { padding-bottom: 48px; }
.pl-48                  { padding-left: 48px; }
.p-48                   { padding: 48px; }
.pt-32                  { padding-top: 32px; }
.pr-32                  { padding-right: 32px; }
.pb-32                  { padding-bottom: 32px; }
.pl-32                  { padding-left: 32px; }
.p-32                   { padding: 32px; }
.pt-16                  { padding-top: 16px; }
.pr-16                  { padding-right: 16px; }
.pb-16                  { padding-bottom: 16px; }
.pl-16                  { padding-left: 16px; }
.p-16                   { padding: 16px; }
.pt-8                   { padding-top: 8px !important; }
.pr-8                   { padding-right: 8px !important; }
.pb-8                   { padding-bottom: 8px !important; }
.pl-8                   { padding-left: 8px !important; }
.p-8                    { padding: 8px !important; }
.pt-0                   { padding-top: 0 !important; }
.pr-0                   { padding-right: 0 !important; }
.pb-0                   { padding-bottom: 0 !important; }
.pl-0                   { padding-left: 0 !important; }
.p-0                    { padding: 0 !important; }
.pb-0                   { padding-bottom: 0 !important; }
.text-primary           { color: $primary; }
.position-absolute      { position: absolute; }
.position-relative      { position: relative; }
.overflow-hidden        { overflow: hidden !important; }
.line-height-1          { line-height: 1; }
.valign-top             { vertical-align: text-top; }
.pointer-events-all     { pointer-events: all !important;}
.font-size-14           { font-size: 14px; }

.z-105 { z-index: 105;}

.no-word-break {
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.subheadline {
    margin: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.abstract {
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.copy {
    margin: 0px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.default-icon {
    font-size: 30px;
}

.small-icon {
    font-size: 22px;
}

.info-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0px 3px;
    background-color: $grey--strong;
    background-image: none;
    mask-image: url("../assets/icons/info.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 12px 12px;
}

.state-failure-txt              { color: $status--failure !important; }
.state-failure-bg               { background-color: $status--failure !important; }
.state-potential-failure-txt    { color: $status--potential-failure !important; }
.state-potential-failure-bg     { background-color: $status--potential-failure !important; }
.state-to-be-monitored-txt      { color: $status--to-be-monitored !important; }
.state-to-be-monitored-bg       { background-color: $status--to-be-monitored !important; }
.state-ok-txt                   { color: $status--ok !important; }
.state-ok-bg                    { background-color: $status--ok !important; }
.state-no-data-txt              { color: $status--no-data !important; }
.state-no-data-bg               { background-color: $status--no-data !important; }

.text-muted {
    color: $grey--strong;
}
